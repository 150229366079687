import {createSlice} from '@reduxjs/toolkit';
import PeacoloAFrameCustomEntityObject from './models/PeacoloAFrameCustomEntityObject';
import PeacoloCustomEntityObjectMapper from './PeacoloCustomEntityObjectMapper';
import {v4} from 'uuid';
import personaliseCardUtils from './personaliseCardUtils';
import axios from 'axios';
import {store_notification_showError} from '../../store/notificationSlice';
import ApiService from '../../services/ApiService';
import PeacoloAFrameUtils from '../../aframe/PeacoloAFrameUtils';

const apiService = new ApiService()
const peacoloCustomEntityObjectMapper = new PeacoloCustomEntityObjectMapper()

const defaultTextProps = new PeacoloAFrameCustomEntityObject({
  type: 'text',
  text_color: 'white',
  text_backgroundColor: 'rgba(0,0,0,0)',
  text_textAlign: 'center',
  entity_positionZ: -6,
  text_value: 'Enter text here...',
  text_wrap: 50,
  text_width: 12,
});




const initialState = {
  cardId: null,
  cardType: null,
  orderId: null,
  entities: [], // Array<{state: newEntityProps_State, html: newEntityProps_Html}>
  camera_rotation: {},
  recipientData: null,
  customMusicUrl: '',
  newEntityProps: null,
  newEntityProps_State: null,
  newEntityProps_Html: null,
  activeStep: 0,
  menuEditMode: null,
  menuEditDrawer: null,
  uploadLoadingMessage: null,
  personalisePage: 0,
  defaultTextProps: {...defaultTextProps},
}

export const PERSONALISE_MENU_EDIT_MODES = {
  TEXT: 'TEXT',
  PHOTOS: 'PHOTOS',
  MUSIC: 'MUSIC',
};

export const PERSONALISE_MENU_DRAWER = {
  TEXT_COLOR: 'TEXT_COLOR',
  TEXT_FONT: 'TEXT_FONT',
  TEXT_SIZE: 'TEXT_SIZE',
  TEXT_INPUT: 'TEXT_INPUT',
  RESET: 'RESET',
};

function calculateEntityPositionFromCameraRotationRadAndDistance(rotation, distance) {
  const rotationOnX = rotation.x / (180 / Math.PI);
  const rotationOnY = rotation.y / (180 / Math.PI);
  const cameraRotationYDeg = rotationOnX * (180 / Math.PI);
  const posY = (-distance) * Math.sin(rotationOnX) + 1.6;

  const cameraRotationXDeg = rotationOnY * (180 / Math.PI);
  const posX = distance * Math.sin(rotationOnY) * Math.cos(rotationOnX);

  // I don't know how I calculated this but it works
  const posZ = distance * Math.cos(rotationOnX) * Math.cos(rotationOnY);

  /*
  The Math is here...
  s = rotationOnY
  t = rotationOnX
  x = r * cos(s) * sin(t) == PosX
  y = r * sin(s) * sin(t)
  z = r * cos(t)
   */
  return {
    position: [posX, posY, posZ],
    rotation: [cameraRotationYDeg, cameraRotationXDeg, 0],
  };
}

export const personaliseSlice = createSlice({
  name: 'counter',
  initialState: {...initialState},
  reducers: {
    store_personaliseSetCardType: (state, action) => {
      state.cardType = action.payload ?? "SOLO"
    },
    store_addEntity: (state, action) => {
      // const id = action.payload.state.id;
      const updatedEntities = [...state.entities];
      // const index = updatedEntities.findIndex(
      //   e => e.state.cardId === cardId)
      // if (index > -1) {
      //   updatedEntities.splice(index, 1)
      // }
      const id = `customEntity_${v4()}`
      updatedEntities.push({
        state: {...state.newEntityProps_State, id: id},
        html: {...state.newEntityProps_Html, id: id}
      });
      state.entities = updatedEntities;
      state.newEntityProps_State = null;
      state.newEntityProps_Html = null;
      state.defaultTextProps = defaultTextProps
      state.uploadLoadingMessage = null;
      state.camera_rotation = {};
    },
    store_removeEntity: (state, action) => {
      const id = action.payload;
      const updatedEntities = [...state.entities];
      const index = updatedEntities.findIndex(e => e.state.id === id);
      if (index > -1) {
        updatedEntities.splice(index, 1);
      }
      state.entities = updatedEntities;
      state.newEntityProps_State = null;
      state.newEntityProps_Html = null;
    },
    store_personaliseResetAllData: (state, action) => {
      state = {...initialState}
      return state
    },
    store_personaliseSetCard: (state, action) => {
      state.card = action.payload;
      state.cardId = action.payload?.id;
      state.uploadLoadingMessage = null;
    },
    store_personaliseSetPage: (state, action) => {
      state.personalisePage = action.payload;
    },
    store_personaliseAddRecipientData: (state, action) => {
      state.recipientData = action.payload;
    },
    store_personaliseSetOrderId: (state, action) => {
      state.recipientData = action.payload;
    },
    store_personaliseUpdateNewEntityHtmlFromState: (state, action) => {
      const vrData = peacoloCustomEntityObjectMapper.convertPeacoloCustomEntityObjectToAFrameEntityProps(
          {...state.newEntityProps_State});
      const entityPropsAsHtml = PeacoloAFrameUtils.convertObjectToHtmlProps({...vrData})
      entityPropsAsHtml.class = 'userCustomiseEntity raycastable'
      state.newEntityProps_Html = entityPropsAsHtml;
    },
    store_personaliseRemoveAllEntities: (state, action) => {
      state.entities = [];
      state.defaultTextProps = initialState.defaultTextProps
      state.menuEditMode = null;
      state.menuEditDrawer = null;
      state.camera_rotation = {};
    },
    store_personaliseAddNewText: (state, action) => {
      state.menuEditMode = PERSONALISE_MENU_EDIT_MODES.TEXT;
      state.menuEditDrawer = PERSONALISE_MENU_DRAWER.TEXT_INPUT;
      const font = action.payload.font
      const id = `customEntity_${v4()}`
      const data =
      state.newEntityProps_State = new PeacoloAFrameCustomEntityObject({
        ...defaultTextProps,
        ...action?.payload,
        id: id,
        type: 'text',
        text_font: font
      });
      state.camera_rotation = {};
    },
    store_personaliseUpdateNewEntityTextColor: (state, action) => {
      state.newEntityProps_State = new PeacoloAFrameCustomEntityObject({
        ...state.newEntityProps_State,
        text_color: action.payload
      });
    },
    store_personaliseUpdateNewEntityTextFont: (state, action) => {
      state.newEntityProps_State = new PeacoloAFrameCustomEntityObject({
        ...state.newEntityProps_State,
        text_font: action.payload
      });
    },
    store_personaliseUpdateNewEntityTextWrap: (state, action) => {
      const currentTextWrap = state.newEntityProps_State.text_wrap
      const currentTextWidth = state.newEntityProps_State.text_width
      const textWrapToWidthRatio = currentTextWidth / currentTextWrap
      const textWrap = action.payload
      const textWidth = Math.round(textWrapToWidthRatio * textWrap)
      state.newEntityProps_State = new PeacoloAFrameCustomEntityObject({
        ...state.newEntityProps_State,
        text_wrap: textWrap,
        // text_width: textWidth
      });
    },
    store_personaliseUpdateNewEntityTextWidth: (state, action) => {
      state.newEntityProps_State = new PeacoloAFrameCustomEntityObject({
        ...state.newEntityProps_State,
        text_width: action.payload
      });
    },
    store_personaliseUpdateNewEntityDistance: (state, action) => {
      const distance = action.payload
      const {position, rotation} = calculateEntityPositionFromCameraRotationRadAndDistance(state.newEntityProps_State.entity_rotation, distance)
      state.newEntityProps_State = new PeacoloAFrameCustomEntityObject({
        ...state.newEntityProps_State,
        entity_positionZ: action.payload,
        entity_position: position,
        entity_rotation: rotation,
      });
    },
    store_personaliseUpdateNewEntityTextInput: (state, action) => {
      state.newEntityProps_State = new PeacoloAFrameCustomEntityObject({
        ...state.newEntityProps_State,
        text_value: action.payload
      });
    },
    store_personaliseUpdateNewEntityRotationAndPosition: (state, action) => {
      console.log('store_personaliseUpdateNewEntityRotationAndPosition', action.payload)
      state.newEntityProps_State = new PeacoloAFrameCustomEntityObject({
        ...state.newEntityProps_State,
        entity_position: Array.from(action.payload.position),
        entity_rotation: Array.from(action.payload.rotation),
      });
      state.camera_rotation = action.payload.cameraRotation
    },
    store_personaliseUpdateNewEntityFixedRotation: (state, action) => {
      // state.newEntityProps_State = new PeacoloAFrameCustomEntityObject({
      //   ...state.newEntityProps_State,
      //   entity_rotation: action.payload
      // });
    },
    store_personaliseResetNewEntity: (state, action) => {
      state.newEntityProps_State = {}
      state.newEntityProps_Html = {}
      state.menuEditMode = null;
      state.menuEditDrawer = null;
    },
    store_personaliseEditEntityById: (state, action) => {
      if (state.menuEditMode != null) {
        return state
      }
      const entityId = action.payload
      const entityIndex = state.entities.findIndex(entity => entity.state.id === entityId)
      if (entityIndex < 0) {
        return
      }

      const entity = {...state.entities[entityIndex]}
      state.newEntityProps_State = new PeacoloAFrameCustomEntityObject({
        ...defaultTextProps,
        ...entity.state,
        entity_position: [state.camera_rotation.x, 1.3, 0],
        entity_rotation: Array.from(state.newEntityProps_State.entity_rotation),
      })
      const newStateEntities = state.entities.filter(entity => entity.state.id !== entityId)
      state.entities = [...newStateEntities]
      state.menuEditMode = PERSONALISE_MENU_EDIT_MODES.TEXT;

    },
    store_personaliseShowTextColorDrawer: (state, action) => {
      state.menuEditDrawer = PERSONALISE_MENU_DRAWER.TEXT_COLOR;
    },
    store_personaliseShowTextSizeDrawer: (state, action) => {
      state.menuEditDrawer = PERSONALISE_MENU_DRAWER.TEXT_SIZE;
    },
    store_personaliseShowTextInputDrawer: (state, action) => {
      state.menuEditDrawer = PERSONALISE_MENU_DRAWER.TEXT_INPUT;
    },
    store_personaliseShowResetDrawer: (state, action) => {
      state.menuEditDrawer = PERSONALISE_MENU_DRAWER.RESET;
    },
    store_personaliseShowTextFontDrawer: (state, action) => {
      state.menuEditDrawer = PERSONALISE_MENU_DRAWER.TEXT_FONT;
    },
    store_personaliseHideDrawers: (state, action) => {
      state.menuEditDrawer = null;
    },
    store_personaliseResetMenu: (state, action) => {
      state.menuEditMode = null;
      state.menuEditDrawer = null;
    },
    store_personalise_setUploadLoadingMessage: (state, action) => {
      state.uploadLoadingMessage = action.payload;
    },

  },
});

export const store_personalise_doUpdateCardId = (newCardId) => async (dispatch, getState) => {
  dispatch(store_personalise_setUploadLoadingMessage("Loading personalisation tool"))
  const state = getState()
  const currentCardId = state.personaliseSlice.cardId
  if (newCardId !== currentCardId) {
    dispatch(store_personaliseResetAllData())
  }
  try {
    const response = await apiService.getCardById(newCardId)
    dispatch(store_personaliseSetCard(response.result))
  } catch (err) {
    dispatch(store_notification_showError(err.message))
  } finally {
    dispatch(store_personalise_setUploadLoadingMessage(null))
  }
}

export const store_personalise_doUpdateNewEntityFromRotation = (cameraRotation) => async (dispatch, getState) => {
  const state = getState()
  const newEntityPropsState = state.personaliseSlice.newEntityProps_State
  const distance = newEntityPropsState?.entity_positionZ
  const {rotation, position} = calculateEntityPositionFromCameraRotationRadAndDistance(cameraRotation, distance);
  dispatch(store_personaliseUpdateNewEntityRotationAndPosition(
      {
        position: position,
        rotation: rotation,
        cameraRotation: cameraRotation
      })
  );
}

export const store_personalise_doSubmitOrder = () => async (dispatch, getState) => {
  dispatch(store_personalise_setUploadLoadingMessage("Building your card..."));

  const state = getState()
  const orderId = state.personaliseSlice.orderId
  const cardId = state.personaliseSlice.cardId
  const cardType = state.personaliseSlice.cardType
  const entities = state.personaliseSlice.entities
  const recipientData = state.personaliseSlice.recipientData
  const customMusicUrl = state.personaliseSlice.customMusicUrl

      // newEntityProps: null,
      // newEntityProps_State: null,
      // newEntityProps_Html: null,
  const entitiesWithAssets = personaliseCardUtils.getImageEntities(entities);

  try {
    const santizedEntities = entities.map(entity => {
      const sanitizedEntity = {...entity};
      sanitizedEntity.state = {...sanitizedEntity.state, imageData: null};
      return sanitizedEntity;
    });

    const options = {...recipientData, customMusicUrl, cardType}

    const cardOrderResponse = await apiService.submitOrder(cardId, options, santizedEntities)
        .then(response => response.result);

    const cardOrder = cardOrderResponse.cardOrder
    const checkoutSessionUrl = cardOrderResponse.checkoutSessionUrl

    dispatch(store_personalise_setUploadLoadingMessage("Uploading your customisations..."));
    for (let i = 0; i < entitiesWithAssets.length; i++) {
      const entity = entitiesWithAssets[i];
      const response = await apiService.getOrderImageUploadUrl(
          orderId, entity?.state?.fileName);
      const {fields, url} = response.result;
      const formData = new FormData();
      for (const [key, value] of Object.entries(fields)) {
        formData.append(key, value);
      }
      // Update the formData object

      const imageDataBlob = await (
          await fetch(entity?.state?.imageData)
      ).blob();
      formData.append(
          'file',
          imageDataBlob,
          entity?.state?.fileName,
      );

      await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(d => {
        console.log(`Uploaded image:`, d);
      });

    }

    window.location.href = checkoutSessionUrl;

  } catch (err) {
    console.error(`Error uploading card`, err);
    // setCardUploadError(err.message);
    dispatch(store_notification_showError(err.message))
  } finally {
    dispatch(store_personalise_setUploadLoadingMessage(null));
  }
}

// Action creators are generated for each case reducer function
export const {
  store_personaliseSetCardType,
  store_addEntity,
  store_removeEntity,
  store_personaliseSetCard,
  store_personaliseAddRecipientData,
  store_personaliseShowTextColorDrawer,
  store_personaliseShowTextFontDrawer,
  store_personaliseShowTextSizeDrawer,
  store_personaliseShowTextInputDrawer,
  store_personaliseShowResetDrawer,
  store_personaliseSetPage,
  store_personaliseResetAllData,
  store_personaliseResetNewEntity,
  store_personaliseUpdateNewEntityHtmlFromState,
  store_personaliseUpdateNewEntityTextColor,
  store_personaliseUpdateNewEntityTextFont,
  store_personaliseUpdateNewEntityTextWrap,
  store_personaliseUpdateNewEntityTextWidth,
  store_personaliseUpdateNewEntityTextInput,
  store_personaliseUpdateNewEntityDistance,
  store_personaliseRemoveAllEntities,
  store_personaliseResetMenu,
  store_personaliseAddNewText,
  store_personaliseEditEntityById,
  store_personaliseUpdateNewEntityRotationAndPosition,
  store_personaliseUpdateNewEntityFixedRotation,
  store_personaliseHideDrawers,
  store_personalise_setUploadLoadingMessage,
} = personaliseSlice.actions;

export const store_personalise_isTextColorDrawerOpen = (state) => state.personaliseSlice.menuEditDrawer === PERSONALISE_MENU_DRAWER.TEXT_COLOR;
export const store_personalise_isTextFontDrawerOpen = (state) => state.personaliseSlice.menuEditDrawer === PERSONALISE_MENU_DRAWER.TEXT_FONT;
export const store_personalise_isTextSizeDrawerOpen = (state) => state.personaliseSlice.menuEditDrawer === PERSONALISE_MENU_DRAWER.TEXT_SIZE;
export const store_personalise_isTextInputDrawerOpen = (state) => state.personaliseSlice.menuEditDrawer === PERSONALISE_MENU_DRAWER.TEXT_INPUT;
export const store_personalise_isResetDrawerOpen = (state) => state.personaliseSlice.menuEditDrawer === PERSONALISE_MENU_DRAWER.RESET;
export const store_personalise_getCameraRotation = (state) => state.personaliseSlice.camera_rotation;
export const store_personalise_isAnyDrawerOpen = (state) => store_personalise_isTextColorDrawerOpen(state)
    || store_personalise_isTextFontDrawerOpen(state)
    || store_personalise_isTextSizeDrawerOpen(state)
    || store_personalise_isTextInputDrawerOpen(state);
export const store_personalise_isAnyMenuOpen = (state) => state.personaliseSlice.menuEditMode != null
export const store_personalise_getNewEntityState = (state) => state.personaliseSlice.newEntityProps_State ?? {};
export const store_personalise_getCardType = (state) => state.personaliseSlice.cardType ?? '';

export const PERSONALISE_DEFAULT_ENTITY_TEXT_PROPS = defaultTextProps
export default personaliseSlice.reducer;